<template>
    <section class="main-content-section">
        <div class="grid grid-pad">
            <div class="col-1-1">
                <h2 class="page-title">{{productDetails}}</h2>
            </div>
        </div>

        <LoadingSpinner v-if="isLoading" />

        <div v-else class="grid grid-pad products-container">
            <ProductCatalogItem 
                :product="product" 
                :order="order" 
                @showModal="showNoItemsSelectedWarning($event)"
                @saveOrderFailed="showModal($event)"
            />
        </div>

        <WarningMessageModal 
            v-show="showWarningModal" 
            @closeModal="closeModal()"
            :title="errorTitle"
            :text="errorMessage" 
        />
    </section>
</template>

<script>
import ProductCatalogItem from '@/components/ProductCatalogItem'
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductCatalog',
    components: { ProductCatalogItem, WarningMessageModal, LoadingSpinner },
    
    data() {
        return {
            showWarningModal: false,
            errorTitle: '',
            errorMessage: ''
        }
    },

    created() {
        this.setDocumentTitle();

        if (this.$store.getters.getProducts === null) {
            this.$store.dispatch('fetchProduct', +this.$route.params.id);
        }
    },

    updated() {
        this.setDocumentTitle();
    },
    
    computed: {
        product() {
            if (this.$store.getters.getProducts !== null) {
                return this.$store.getters.getProductById(+this.$route.params.id);
            }
            return this.$store.getters.getProduct;
        },
        ...mapGetters([
            'isLoading',
            'metaTitle',
            // Texts
            'productDetails',
            'youHaveNotSelectedAnyProducts',
            'addAtLeastOneItemToYourShoppingList'
        ]),
        ...mapGetters({order: 'getOrder'})
    },

    methods: {
        setDocumentTitle() {
            document.title = this.productDetails + this.metaTitle;
        },

        showNoItemsSelectedWarning(e) {
            this.errorTitle = this.youHaveNotSelectedAnyProducts;
            this.errorMessage = this.addAtLeastOneItemToYourShoppingList;
            this.showWarningModal = e;
        },

        showModal(e) {
            this.errorTitle = e.title;
            this.errorMessage = e.detail
            this.showWarningModal = true;
        },

        closeModal() {
            this.errorTitle = '';
            this.errorMessage = '';
            this.showWarningModal = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.products-container {
    margin-bottom: 4rem;
}
</style>